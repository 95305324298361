<template>
    <v-card
    class="shipping_map_dialog"
    >
        <v-toolbar
          dark
          color="primary"
          fixed
        >
          <v-btn
            icon
            dark
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Shipping settings: {{ store.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          </v-toolbar>
          <template v-if="!loading">
            <v-alert
              v-if="disable_edit"
              class="ma-12"
              type="warning"
              outlined
              >
              {{ !get_plan || !get_plan.max_carriers ? 
                'Your current plan does not allow Shipping routing to be edited. Upgrade to a plan that allows carrier accounts. Your previously saved Shipping maps are accessible.' 
                :
                'You can not edit shipping routing on a store that has transfered the fulfillment' }}
            </v-alert>
            <v-container class="pa-4" fluid>
            <v-expansion-panels v-model="panels">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Fulfillment Location
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <FulfillmentLocationSelect 
                    :store="store"
                    />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Carrier App Rates
                  <router-link
                    class="ml-2"
                    :to="'carriers'"
                    v-if="!company.carriers.length"
                    >Add carrier accounts</router-link>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="company.carriers.some(c => (c.type === 'postnord' || c.type === 'fedex')) && !store.auto_carrier_services">
                      <div class="d-flex align-center justify-start" style="gap: 1em">
                        <v-btn
                          color="primary"
                          @click="connect_auto_carrier"
                          :loading="adding_new_loading"
                          >
                            Activate on shop
                        </v-btn>
                      </div>
                  </template>
                  <v-alert 
                    type="info"
                    dense
                    v-else-if="!store.auto_carrier_services"
                    >
                    <div
                      class="d-flex justify-space-around align-center"
                      >You need to connect a Postnord or FedEx carrier to Fulfillment Hero.
                      <v-btn
                        class="ml-2"
                        link
                        to="carriers"
                        >Add carrier accounts</v-btn>
                    </div>
                  </v-alert>
                  <AutoCarrierServiceCard
                    :store="store"
                    :carrier_service="store.auto_carrier_services"
                    @delete="delete_auto_carrier(carrier_service.id)"
                    />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>

            <v-expansion-panel-header>
              Unmapped manual shipping methods ({{ get_unmapped_zones(store.id).length }})
              <router-link
                class="ml-2"
                :to="'carriers'"
                v-if="!company.carriers.length"
                >Add carrier accounts</router-link>
                
            </v-expansion-panel-header>
            <!-- <v-card-text class="mt-4 px-4"> -->
              <v-expansion-panel-content>
                <ShippingMapCard 
                  v-for="(type, index) in get_unmapped_zones(store.id)"
                  :key="`type-${index}`" 
                  :shipping_method="type"
                  :store="store"
                  @save="save_map"
                  :disabled="disable_edit"
                  />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>

            <v-expansion-panel-header>
              Mapped manual shipping methods ({{ store.shipping_map.length }})
              <router-link
                class="ml-2"
                :to="'carriers'"
                v-if="!company.carriers.length"
                >Add carrier accounts</router-link>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            <!-- <v-card-text class="px-4"> -->

              <ShippingMapCard 
                v-for="(map, index) in store.shipping_map"
                :key="`mapped-${index}`" 
                :shipping_method="map"
                :store="store"
                @save="update_map({ index, map: $event})"
                @remove="remove_map(index)"
                :disabled="disable_edit"
                />
            </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
            </v-container>
            </template>
            <template v-else>
              <v-card-text class="text-center mt-12">
                  <v-progress-circular
                    indeterminate
                    size="50"
                    color="primary"
                  ></v-progress-circular>
              </v-card-text>
            </template>
      </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ShippingMapCard from '@/components/ShippingMapCard'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'
import AutoCarrierServiceCard from '@/components/AutoCarrierServiceCard.vue'
// import ExpandableChipGroup from '@/components/ExpandableChipGroup.vue'

export default {
  components: {
    ShippingMapCard,
    FulfillmentLocationSelect,
    AutoCarrierServiceCard,
    // ExpandableChipGroup,
  },
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  computed: {
    // ...mapFields(['warehouse.company.data_sources[]']),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      get_unmapped_zones: 'shipping/get_unmapped_zones'
    }),
    ...mapState({
      company: state => state.warehouse.company,
    }),
    disable_edit(){
      return !this.get_plan.max_carriers || (this.store.fulfilling_company_id !== this.company.id)
    },

  },
  data() {
    return {
      loading: true,
      adding_new: false,
      adding_new_loading: false,
      // selected_auto_carrier: null,
      // location: null,
      panels: 1,
    }
  },
  methods: {
    async delete_auto_carrier(id){
      await this.$store.dispatch('warehouse/delete_auto_carrier', { 
          ds_id: this.store.id,
          auto_carrier_service_id: id,
        })
    },
    async connect_auto_carrier(){
      this.adding_new_loading = true
      await this.$store.dispatch('warehouse/connect_auto_carrier', { 
          ds_id: this.store.id,
          // auto_carrier_service: this.selected_auto_carrier,
        })
      this.adding_new = false
      this.adding_new_loading = false
    },
    async remove_map(index){
      const shipping_map = this.store.shipping_map
      shipping_map.splice(index, 1)
      // this.$store.commit('warehouse/SET_DATA_SOURCE', { id: this.store.id, shipping_map })
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map,
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    },
    async update_map({ map, index }){
      console.log(index, map);
      const shipping_map = this.company.data_sources.filter(ds => ds.id === this.store.id)[0].shipping_map
      shipping_map[index] = map
      this.$store.commit('warehouse/UPDATE_SHIPPING_MAP', { map_index: index, map, store_id: this.store.id })
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    },
    async save_map(req){
      console.log('save', req);
      const shipping_map = this.company.data_sources.filter(ds => ds.id === this.store.id)[0].shipping_map.concat([req])
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    }
  },
  async mounted(){
    console.log('mount');
    // this.location_id = this.store.location_id
    await this.$store.dispatch('shipping/get_shipping_zones', this.store.id)
    console.log('done');
    this.loading = false
  }
}
</script>
