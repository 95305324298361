<template>
    <v-card
      round
      class="my-4 pb-2"
      outlined
      >

    <v-card-subtitle class="my-3">
      <v-row>
        <v-col class="grow">
          Remember to add Fulfillment Hero as a Carrier App Rate on Shopify (>Settings>Shipping&Delivery). By using Fulfillment Hero Carrier App Rates you acknowledge that Fulfillment Hero can not be held
          responsible for faulty quotes provided to customers no matter the reason. The most recent price lists will always be used, you may add or reduce the price in % within Shopify. As Fulfillment Hero does not know the physical size 
          of an order (only weight) shipping quotes presented to customers will not reflect over sized orders (although separate items can be tagged oversized). All prices displayed are inclusive of VAT.
        </v-col>
        <v-col class="shrink">
          <v-dialog
            v-model="delete_carrier_confirm"
            >
            <template v-slot:activator="{ on, attrs }">
            <v-btn icon outlined v-bind="attrs" v-on="on">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            </template>
            <v-card>
              <v-card-title>
                Confirm uninstall
              </v-card-title>
              <v-card-text>
                This will remove Carrier App Rates from Shopify including all shipping methods where it is currently in use.
              </v-card-text>
              <v-card-actions>
                <v-btn
                @click="delete_carrier_confirm = false"
                  text>Cancel</v-btn>
                  <v-btn
                  @click="delete_carrier"
                  text>Confirm</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <template v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"/>
      </template>
      <template v-else>
        <v-alert
          v-if="!get_plan || !get_plan.allow_auto_carrier"
          type="info"
          outlined
          >
          <v-row>
            <v-col class="grow">
              Upgrade your plan to use Carrier App Rates
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="$router.push('/auth/plans')"
                color="primary"
                >
                View Plans</v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <!-- <AutoCarrierServiceComponent
          v-for="(service, key) in carrier_service.services"
          :key="key"
          :carrier_service="carrier_service"
          :service="service"
          />
        <AutoCarrierServiceComponent 
          v-if="adding_new"
          :carrier_service="carrier_service"
          />
        <v-btn
          color="primary"
          v-if="!adding_new"
          @click="adding_new = true"
          >Add service</v-btn> -->
          <AutoCarrierServiceComponent 
            v-for="(service, key) in service_charges"
            :carrier_service="carrier_service"
            :key="key"
            :service="service"
            :loading="loading_card === service.id"
            :store="store"
            @save="new_service => save_service(new_service, service.id)"
            @deactivate="deactivate(service.id)"
            class="my-4"
            />
      </template>

    </v-card-text>
    </v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'

import AutoCarrierServiceComponent from '@/components/AutoCarrierServiceComponent'

export default {
  components: {
    AutoCarrierServiceComponent
  },
  props: {
    store: {
      type: Object,
      required: true,
      default: null
    },
    carrier_service: {
      type: Object,
      required: true,
      default: null
    },
  },
  computed: {
   ...mapState({
      company: s => s.warehouse.company,
      shipping: state => state.shipping,
      service_charges: function(s) { return s.shipping.service_charges.sort((a, b) => a.name.localeCompare(b.name))},
   }),
   ...mapGetters({
    get_plan: 'warehouse/get_plan',
   }),
  //  carrier: function (){ return this.company?.carriers.find(c => c.id === this.carrier_service.sf_carrier_id) },
   available_service_charges: function() { return this.service_charges?.filter(s => !this.carrier_service.active_services.some(acs => acs.sf_id === s.id)) },
  },
  watch: {
   
  },
  data() {
    return {
      loading: true,
      adding_new: false,
      loading_card: '',
      delete_carrier_confirm: false,
    }
  },
  methods: {
    delete_carrier() {
      this.loading = true
      this.delete_carrier_confirm = false
      this.$emit('delete')
    },
   async save_service(new_service){
    console.log('at save_service', new_service)
    this.loading_card = new_service.sf_id
    await this.$store.dispatch('warehouse/save_data_source', {
      id: this.store.id,
      auto_carrier_services: {
          ...this.store.auto_carrier_services,
          active_services: this.store.auto_carrier_services.active_services
              .filter(s => s.sf_id !== new_service.sf_id)
              .concat([ new_service ])
      }
    })
    this.loading_card = ''
   },
   async deactivate(sf_id){
    this.loading_card = sf_id
    await this.$store.dispatch('warehouse/save_data_source', {
      id: this.store.id,
      auto_carrier_services: {
        ...this.store.auto_carrier_services,
          active_services: this.store.auto_carrier_services.active_services
              .filter(s => s.sf_id !== sf_id)
      }

    })
    this.loading_card = ''
   }
  },
  async mounted(){
    // if(!this.service_charges[this.carrier_service.type]?.length) await this.$store.dispatch('shipping/get_service_charges', this.carrier_service.type)
    if(!this.service_charges?.length) await this.$store.dispatch('shipping/get_service_charges')
    this.loading = false
  }
}
</script>